(function($, window, document) { // jQuery wrapper

    // Pagination Functions
    paginationFunctions = {

        container: '',

        // Setups all block functions (each function needs to be called within this function)
        setup : function() {
            // Get the containers
            paginationFunctions.container = $('.j-products');

            // If there are containers, run the function.
            if( paginationFunctions.container.length > 0 ){

                paginationFunctions.ajaxLoadMore();
                paginationFunctions.popState();
            }
        },
        
        popState: function(){
            window.onpopstate = function(e){
                if(e.state !== null) { // state data available
                    // load the page using state data
                    location.reload();
            
                } else { // no state data available
                    // load initial page which was there at first page load
                }
            };
        },
        // Setup the ajax LoadMore functionality
        ajaxLoadMore: function(){
            // When load more button is clicked get the page, add the posts and replace the pagination button.
            paginationFunctions.container.on('click', '.j-products--categories a', function(e){
                e.preventDefault();
                paginationFunctions.replaceContent($(this).attr('href'));
            });
            paginationFunctions.container.on('submit', '.j-products--search-form', function(e){
                e.preventDefault();
                paginationFunctions.replaceContent( $(this).attr('action') + "?" +  $(this).serialize() );
            });
            paginationFunctions.container.on('click', '.j-products--pagination a', function(e){
                e.preventDefault();
                paginationFunctions.replaceContent($(this).attr('href'));

                $('html, body').animate({
                    scrollTop: $(".j-products--posts").offset().top - 200
                }, 500);
            });

            paginationFunctions.container.on('click', '.j-products--toggle', function(e){
                console.log('clicked');
                paginationFunctions.container.find('.j-products--dropdown').slideToggle();
            });
            
            paginationFunctions.container.on('click', '.j-products--option', function(e){
                e.preventDefault();
                paginationFunctions.replaceContent($(this).attr('href'));
                
                paginationFunctions.container.find('.j-products--value').text($(this).text());

                // $('html, body').animate({
                //     scrollTop: $(".j-products--posts").offset().top - 200
                // }, 500);
            });
            

            
        },
        updateHistory: function(url, newPage){
            history.pushState({}, "", url);
        },
        replaceContent: function(url){
            window._fetch.fetch(url, function(newPage){
                $('.j-products--search'     ).html(newPage.find('.j-products--search').html()); // replace Search Box
                $('.j-products--select'     ).html(newPage.find('.j-products--select').html()); // replace Select Box
                $('.j-products--posts'      ).html(newPage.find('.j-products--posts').html()); // add new posts
                $('.j-products--categories' ).html(newPage.find('.j-products--categories').html()); // replace filters
                $('.j-products--pagination' ).html(newPage.find('.j-products--pagination').html()); // replace pagination
            });
            paginationFunctions.updateHistory(url);
        },

    };
    window.PaginationFunctions = paginationFunctions;
}(window.jQuery, window, document)); // End of jQuery wrapper

