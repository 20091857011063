(function($, window, document) { // jQuery wrapper

    // Page Functions
    window.PageFunctions = {

        stickyHeader: function(){
            // Sticky Header
            var header = $('.o-header');
            function checkHeaderPosition(){
                var scroll = $(window).scrollTop();
                if (scroll >= 50) {
                    header.addClass('o-header__scrolled is_sticky');
                } else {
                    if (scroll <= 5) {
                        header.removeClass('o-header__scrolled is_sticky');
                    }
                }
            }
            checkHeaderPosition();
            //caches a jQuery object containing the header element
            $(window).scroll(function() {
                checkHeaderPosition();
            });
        },

        // Form Inputs

        formInputFocus: function(){
            window.PageFunctions.formInputFocusLogic();

            $(document).on('gform_page_loaded', function(){

                window.PageFunctions.formInputFocusLogic();
            });

        },
        formInputFocusLogic: function(){

            $('.gform_body').find('select').select2();


            $('.gform_body').find('input:not([type="radio"], [type="checkbox"]), textarea').each(function(){
                if( $(this).val().length > 0 ){
                    var gfield = $(this).parents('.gfield');
                    if( ! gfield.hasClass('gfield-type__fileupload') ){
                        gfield.addClass('input--focus');
                    }
                }
            });
            $('.gform_body').find('input:not([type="radio"], [type="checkbox"]), textarea').each(function(){
                var gfield = $(this).parents('.gfield');
                gfield.addClass('input--fancy-label');
            });

            $('.gform_body').on('focus','input, textarea', function(){
                var gfield = $(this).parents('.gfield');
                if( ! gfield.hasClass('gfield-type__fileupload') ){
                    gfield.addClass('input--focus');
                }
            }).on('focusout','input',function(){
                if( $(this).val().length === 0 ){
                   
                    var gfield = $(this).parents('.gfield');
                    if( ! gfield.hasClass('gfield-type__fileupload') ){
                        gfield.removeClass('input--focus');
                    }
                }
            });

            $('.gform_body').on('change','.gfield-type__fileupload input',function(){
                console.log('file changed');
                console.log( $(this) );

                var gfield = $(this).parents('.gfield');
                var label = gfield.find('.gfield_label');
                var labelVal = label.innerHTML;
                var fileName = '';

                fileName = $(this).val().split('\\').pop();

                if( fileName ){
                    label.text(fileName);
                }else{
                    label.innerHTML = labelVal;
                }
            });
            
        },

        // Block Functions
        blockFunctions: function(){
            // Call all block functions from here.
            window.PageFunctions.productCallouts();
            window.PageFunctions.productMethods();
            window.PageFunctions.ctaForm();
            window.PageFunctions.testimonials();
            window.PageFunctions.faqs();
            window.PageFunctions.gallery();
        },
        testimonials: function(){
            var testimonials = $('.j-testimonials');
            if ( testimonials.length > 0 ){
                testimonials.slick({
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    
                });
            }
        },
        productCallouts: function(){
            var callouts = $('.j-product-callouts');
            if ( callouts.length > 0 ){
                callouts.slick({
                    dots: true,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    responsive: [
                        {
                          breakpoint: 950,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                          }
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                arrows: false,
                                centerMode: true,
                                // centerPadding: '55px',
                                centerPadding: '20%',
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 500,
                            settings: {
                                arrows: false,
                                centerMode: true,
                                centerPadding: '25px',
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            }
        },

        faqs: function(){
            var faqs = $('.j-faqs');
            if ( faqs.length > 0 ){
                faqs.on('click','.j-faqs--toggle', function(){
                    var faq = $(this).parents('.j-faqs--faq');
                    faq.toggleClass('is_active').find('.j-faqs--body').slideToggle();
                });
            }
        },

        gallery: function(){
            var gallery = $('.j-gallery');
            if ( gallery.length > 0 ){
                var main = $('.j-gallery--main');
                var thumbnails = $('.j-gallery--thumbnails');

                main.slick({
                    dots: false,
                    arrows: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    asNavFor: $('.j-gallery--thumbnails'),
                    adaptiveHeight: true
                });
                thumbnails.slick({
                    dots: false,
                    arrows: false,
                    slidesToShow: 6,
                    slidesToScroll: 6,
                    asNavFor: $('.j-gallery--main'),
                    focusOnSelect: true
                });
            }
        },

        ctaForm: function(){

            $('.j-cta').on('click','button.submit', function(e){    
                var _this = $(this);
                var container = _this.parents('.j-cta');
                if(container.hasClass('j-cta__open')){
                    return;
                }else{
                    e.preventDefault();
                    container.addClass('j-cta__open');
                    container.find('.gform_body').slideDown();
                }
            });
        },


        productTabs: function(){
            $('.j-tabs').on('click','.j-tabs--control', function(e){    
                var _this = $(this);
                if (_this.hasClass('active')){
                    return;
                }
                var tab = _this.data('tab');
                // _this.addClass('active');
                $('.j-tabs').find('[data-tab="' + tab + '"]').addClass('active').siblings().removeClass('active');
            });
        },

        productMethods: function(){
           
            $('.j-methods').on('click','.j-methods--title', function(){
                $(this).parent().addClass('active--method').siblings().removeClass('active--method');
            });
        },


        productIndexAjax: function(){
           var container = $('.j-products');

           container.on('click','.j-products--category',function(){

           });
        }

    };

}(window.jQuery, window, document)); // End of jQuery wrapper

